import React from "react"
import Head from "./Head"
import Header from "./Header"
import "@fontsource/noto-sans-jp/500.css"

const Layout = ({ title, description, children }) => {
  return (
    <>
      <Head title={title} description={description} />
      <Header />
      <div id="wrapper" className="wrapper">
        {children}
      </div>
    </>
  )
}

export default Layout
