import React from "react"
import Helmet from "react-helmet"

const Head = ({ title, description }) => {
  return (
    <Helmet
      htmlAttributes={{
        lang: "ja",
      }}
      title={(title ? title + " | " : "") + "CRISP"}
    >
      <meta name="format-detection" content="telephone=no" />
      <meta
        name="keywords"
        content="CRISP SALAD WORKS クリスプ クリスプサラダワークス カスタムサラダ コネクティッド・レストラン フードテック"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/asset/img/favicon/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/asset/img/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/asset/img/favicon/favicon-16x16.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="/asset/img/favicon/android-chrome-192x192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="256x256"
        href="/asset/img/favicon/android-chrome-256x256.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="512x512"
        href="/asset/img/favicon/android-chrome-512x512.png"
      />
      <link
        rel="mask-icon"
        href="/asset/img/favicon/safari-pinned-tab.svg"
        color="#0c3b2e"
      />
      <meta name="description" content={description} />
      <meta
        name="viewport"
        content="initial-scale=1.0,minimum-scale=1,maximum-scale=5"
      />
      <meta name="msapplication-TileColor" content="#0c3b2e" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="Twitter:site" content="@crispsaladworks" />
      <meta name="twitter:title" content="CRISP" />
      <meta
        name="twitter:description"
        content="「レストラン体験を再定義することで、あらゆる場所でリアルなつながりをつくる。」テクノロジーを利用して非連続な成長と高い収益率を実現する「コネクティッド・レストラン」を構築します"
      />
      <meta
        name="twitter:image"
        content="https://corp.crisp.co.jp/asset/img/ogp/ogp.jpg"
      />
      <meta property="og:title" content="CRISP" />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        content="https://corp.crisp.co.jp/asset/img/ogp/ogp.jpg"
      />
      <meta property="og:url" content="https://corp.crisp.co.jp/" />
      <meta
        property="og:description"
        content="「レストラン体験を再定義することで、あらゆる場所でリアルなつながりをつくる。」テクノロジーを利用して非連続な成長と高い収益率を実現する「コネクティッド・レストラン」を構築します"
      />
      {/*<link rel="stylesheet preload" as="style" type="text/css" href="/asset/css/bundle.css" />*/}
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@700&amp;display=swap"
        as="style"
        rel="stylesheet preload"
        media="screen and (max-width:768px)"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&amp;display=swap"
        as="style"
        rel="stylesheet preload"
        media="screen and (min-width:767px)"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@700&amp;display=swap&text=私たちは、サラダを売るだけの外食企業ではありません。届けていくのは、CRISPらしいレストラン体験です。創業当時は、とにかく素敵なお客様と仲間に囲まれた良いお店をつくりたい、という想いを込めてCRISP SALAD WORKS1号店を開業しました。その想いは変わりませんが、今では私たちのやりたいことはもっともっと⼤きく、外⾷のビジネスモデルを180度ひっくり返すような「新しい外⾷」を⽬指しています。私たちは、飲食にテクノロジーの力を取り入れて、オンラインでもオフラインでも、あらゆる場所で人と人とのリアルなつながりが感じられるレストラン体験をつくっていきます。自分たちが信じるものに手を抜かずに、仲間に、商品に、お店に、そしてお客様に愛情をもって本気で向き合いながら。日本の外食のあり方を変え、世の中を変える。私たちCRISPは、共に働くパートナーとお店を愛するファンと一緒に、飲食の未来をもっと面白くしていきます。"
        as="style"
        rel="stylesheet preload"
        media="handheld"
      />
      <link
        rel="stylesheet preload"
        as="style"
        type="text/css"
        href="/asset/css/MyFontsWebfontsKit.css"
      />
    </Helmet>
  )
}

export default Head
